import { durationToMinutes, fmtDate, fmtTime, isToday, plusMinute, setTime } from "./dates";
import { addChildEvent, addEvent, closeHamburger } from "./events";
import { blinkButton, titleAddAttention, titleRemoveAttention, updateRowCounter } from "./html";
import { modal } from "./modal";
import { saveSettings, settings } from "./settings";

export function startRepeatTimer() {
  checkTimers();
  setInterval(checkTimers, 60000);
  addEvent('click', '.repeated-timer', hideTimerAlarm);
}

function repeatTimerHtml(ctr = 0, name = "", starttime = "", endtime = "", repeatafter = "") {
  const html = `
      <th scope="row" class="row-ctr">${ctr}</th>
      <td><input type="text" class="form-control form-control-sm" name="name" placeholder="Timer name" value="${name}" required></td>
      <td><input type="text" class="form-control form-control-sm" name="start" placeholder="HH:mm" value="${starttime}" 
        pattern="([01]?\\d|20|21|22|23):[012345]?\\d"  required></td>
      <td><input type="text" class="form-control form-control-sm" name="end" placeholder="HH:mm" value="${endtime}" 
        pattern="([01]?\\d|20|21|22|23):[012345]?\\d"  required></td>
      <td><input type="text" class="form-control form-control-sm" name="repeat" value="${repeatafter}" 
        pattern="\\d+[mh]" required></td>
      <td><button class="btn btn-danger delete" type="button">Delete</button></td>
  `;
  return html;
}
export function editRepeatTimers() {
  closeHamburger();
  let form = '';
  let ctr = 0;
  if (settings.repeattimers) {
    settings.repeattimers.forEach(timer => {
      const name = timer.name;
      const starttime = fmtTime(timer.starttime);
      const endtime = fmtTime(timer.endtime);
      const repeatafter = timer.repeatafter;
      const lastack = timer.lastack;
      ctr++;
      form += '<tr>' + repeatTimerHtml(ctr, name, starttime, endtime, repeatafter) + '</tr>';
    });
  }
  form = `<form>
  <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Start</th>
      <th scope="col">End</th>
      <th scope="col">Repeat</th>
      <th scope="col">Delete?</th>
    </tr>
  </thead><tbody>${form}
    <tr class="actions"><td colspan="6" class="text-end"><button type="button" class="btn btn-outline-success add">Add</button></td></tr>
  </tbody></table></form>`;
  const modalEl = modal('repeat-timers', 'Configure Repeated Timers', 
  form, 'Save', 
    () => {return true;}, 'Cancel',
    () => {});
    addChildEvent(modalEl, 'click', '.modal-submit', (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const form = modalEl.querySelector('form');
      if (form.checkValidity() === false) {
        form.classList.add('was-validated');
      } else {
        const newTimers = [];
        form.querySelectorAll('tbody tr:not(.actions)').forEach(tr => {
          const timer = {};
          timer.name = tr.querySelector('[name="name"]').value;
          timer.starttime = tr.querySelector('[name="start"]').value;
          timer.endtime = tr.querySelector('[name="end"]').value;
          timer.repeatafter = tr.querySelector('[name="repeat"]').value;
          newTimers.push(timer);
        });
        settings.repeattimers = newTimers;
        saveSettings();
        window.location.reload();
      }
    });
    addChildEvent(modalEl, 'click', '.delete', (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const delButton = evt.target;
      const table = delButton.closest('table');
      delButton.closest('tr').remove();
      updateRowCounter(table);
    });
    addChildEvent(modalEl, 'click', '.add', (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const addButton = evt.target;
      const table = addButton.closest('table');
      const tbody = addButton.closest('tbody');
      const addRow = addButton.closest('tr');
      const newHtml = repeatTimerHtml();
      const tr = document.createElement('tr');
      tr.innerHTML = newHtml;
      tbody.insertBefore(tr, addRow);
      updateRowCounter(table);
    });
}

function timerid(name) {
  const niceName = name.replace(/[^a-z0-9]/gi, '');
  const id = 'repeated-timer-alarm-' + niceName;
  return id;
}

function showTimerAlarm(name) {
  const id = timerid(name);
  if (document.getElementById(id)) {
    console.log(`Repeated timer ${name} already shown.`);
    return;
  }
  const timer = findTimerByName(name);
  timer.shownsince = new Date();

  console.log(`Now showing repeated timer ${name}.`);
  const container = document.getElementById('hamburger-container');
  const btn = blinkButton(name, id);
  btn.classList.add('float-right', 'repeated-timer');
  btn.title = `Since ${fmtTime(timer.shownsince)}. `;
  if (timer.lastack) {
    btn.title += `Last acknowledged at ${fmtTime(timer.lastack)}.`;
  }
  container.appendChild(btn);
  titleAddAttention();
  saveSettings();
}

function hideTimerAlarm(evt) {
  const timerEl = this;
  const id = timerEl.id;
  timerEl.remove();
  titleRemoveAttention();
  
  const timer = findTimerById(id);
  if (timer) {
    timer.shownsince = false;
    timer.lastack = new Date();
  }
  saveSettings();
}

function findTimerById(id) {
  let found = null;
  if (settings.repeattimers) {
    settings.repeattimers.forEach(timer => {
      if (timerid(timer.name) === id) {
        found = timer;
      }
    });
  }
  return found;
}

function findTimerByName(name) {
  let found = null;
  if (settings.repeattimers) {
    settings.repeattimers.forEach(timer => {
      if (timer.name === name) {
        found = timer;
      }
    });
  }
  if (!found) {
    found = {};
  }
  return found;
}

function checkTimers() {
  console.log("checkTimers: " + settings.repeattimers);
  if (settings.repeattimers) {
    settings.repeattimers.forEach(timer => {
      const name = timer.name;
      const now = new Date();
      const starttime = setTime(now, timer.starttime);
      const endtime = setTime(now, timer.endtime);
      const repeatafter = durationToMinutes(timer.repeatafter);
      console.log(`Timer ${name}: ${starttime} - ${endtime}, every ${repeatafter} minutes.`);
      let lastack = timer.lastack;
      if (lastack && !isToday(lastack)) {
        console.log(`Timer ${name}: Remove lastack ${lastack} of yesterday.`);
        lastack = timer.lastack = null;
        timer.acktoday = null;
      }
      if (!timer.acktoday) {
        timer.acktoday = 0;
      }

      if (now < endtime) {
        if (lastack) {
          const next = plusMinute(lastack, repeatafter);
          if (now >= next) {
            console.log(`Timer ${name}: Next alarm ${next} passed.`);
            showTimerAlarm(name);
          } else {
            console.log(`Timer ${name}: Next alarm ${next} NOT reached.`);
          }
        } else {
          if (now >= starttime) {
            console.log(`Timer ${name}: Start time ${starttime} passed.`);
            showTimerAlarm(name);
          } else {
            console.log(`Timer ${name}: Start time ${starttime} NOT reached.`);
          }
        }
      }
    });
  }
}