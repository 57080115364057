import { translate } from './i18n';
import * as bootstrap from 'bootstrap';

function fmt(text, ...args) {
  return text.replace(/{(\d+)}/g, function(match, number) { 
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
}

export function toast(text, color, ...args) {
  return toastWithDelay(2000, text, color, ...args);
}

export function toastNoHide(text, color, ...args) {
  hideAllNoHideToasts();
  return toastWithDelay(false, text, color, ...args);
}

export function hideAllNoHideToasts() {
  document.querySelectorAll('div.toast-no-hide').forEach(to => {
    bootstrap.Toast.getInstance(to).hide();
  });
}

function toastWithDelay(delay, text, color, ...args) {
  color = color || '#007aff';
  let spinner = '';
  if (!delay) {
    spinner = `&nbsp;<span class="spinner-border spinner-border-sm ms-2 mt-2" role="status" aria-hidden="true"></span>`;
  }
  const content = `
  <div class="toast-body">
  <svg class="bd-placeholder-img rounded me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="${color}"></rect></svg>
    ${fmt(translate(text), ...args)}${spinner}
  </div>`;
  const el = document.createElement('div');
  el.classList.add('toast');
  if (!delay) {
    el.classList.add('toast-no-hide');
  }
  el.innerHTML = content;
  const container = document.getElementById('toast-container');
  if (delay) {
    return selfDestroyToast(container, el, {delay: delay});
  } else {
    return selfDestroyToast(container, el, {autohide: false});
  }
}

function selfDestroyToast(container, element, options = {}) {
  container.appendChild(element);
  element.addEventListener('hidden.bs.toast', function () {
    try {
      container.removeChild(element);
    } catch (error) {
      console.log('Error in hideToast.', error);
    }
  });
  const tst = new bootstrap.Toast(element, options);
  tst.show();
  return tst;
}

export function toastError(text, color, ...args) {
  color = color || '#007aff';
  const content = `
  <div class="toast-body">
    ${fmt(translate(text), ...args)}
  </div>`;
  const el = document.createElement('div');
  el.classList.add('toast');
  el.style = 'border: 2px solid red; border-radius: 4px;';
  el.innerHTML = content;
  const container = document.getElementById('toast-container');
  const tst = selfDestroyToast(container, el, {autohide: false});
  el.addEventListener('click', () => tst.hide());
}
