import { toastError, toastNoHide } from "./alerts";
import { findSignedOutCalendarItems, updateCalendarEventTodos } from "./calendars";
import { plusWeek, startOfDay } from "./dates";
import { closeHamburger } from "./events";
import { loadScript, updateSigninStatus } from "./html";
import { hideModalSelector } from "./ui";

const color = '#EF6C00';

const MSAPI = 'https://alcdn.msauth.net/browser/2.15.0/js/msal-browser.min.js';
const CLIENT_ID = 'c54c794b-120f-4114-a804-4306bcfb52c2';
const SCOPES = ['Calendars.Read', 'User.Read'];

globalThis.tioRedirectUri = window.location.href.split('/').slice(0, 3).join('/');

export function o365AuthInit() {
  const o365Block = document.getElementById('o365-authentication');
  if (o365Block) {
    document.getElementById('connect-o365').addEventListener('click', function () {
      hideModalSelector('#o365Modal');
      initApi(initializeMSAL);
    });
    o365Block.querySelector('.btn-logout').addEventListener('click', function () {
      closeHamburger();
      signout();
    });
  }

  const oldToken = localStorage.getItem('mstoken');
  if (oldToken) {
    initApi(() => initializeMSAL(oldToken));
  }
}

function initApi(callback) {
  loadScript(MSAPI, 'o365-script', callback);
}

function signout() {
  localStorage.removeItem("mstoken");
  updateSigninStatus('o365', false, false, () => findSignedOutCalendarItems('o365'));
}

function initializeMSAL(oldToken) {
  const msalConfig = {
    auth: {
      clientId: CLIENT_ID,
      redirectUri: globalThis.tioRedirectUri
    }
  };

  const msalInstance = new msal.PublicClientApplication(msalConfig);

  const loginRequest = {
    scopes: SCOPES
  };

  async function ui_updateSigninStatus(isSignedIn, token) {
    localStorage.setItem("mstoken", token);
    updateSigninStatus('o365', isSignedIn, await userInfo(token),
      () => getCalendarEvents(token), false);
  }

  function getAccessToken() {
    const account = msalInstance.getAllAccounts()[0];
    if (!account) {
        signIn();
        return;
    }

    msalInstance.acquireTokenSilent({
        scopes: ["User.Read", "Calendars.ReadWrite"],
        account: account
    })
    .then(response => {
      ui_updateSigninStatus(true, response.accessToken);
    })
    .catch(error => {
        console.error(error);
        msalInstance.acquireTokenPopup(loginRequest)
            .then(response => {
              ui_updateSigninStatus(true, response.accessToken);
            })
            .catch(error => {
                console.error(error);
            });
    });
  }
  
  function signIn() {
    msalInstance.loginPopup(loginRequest)
      .then(response => {
        console.log("Login successful");
        ui_updateSigninStatus(true, response.accessToken);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function userInfo(token) {
    const name = await fetch("https://graph.microsoft.com/v1.0/me", {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        let name = data.displayName;
        if (!name) {
          name = data.userPrincipalName;
        }
        return name;
      })
      .catch(error => {
        console.error(error);
      });
      return name;
  }
  function getCalendarEvents(token) {
    console.log("Loading O365 Calendar events.");
    const tst = toastNoHide('Loading O365 Calendar', color);
    const startDateTime = startOfDay(new Date(), 0);
    const endDateTime = plusWeek(startDateTime, 8);

    fetch(`https://graph.microsoft.com/v1.0/me/calendarView?startDateTime=${startDateTime.toISOString()}&endDateTime=${endDateTime.toISOString()}&$select=subject,body,bodyPreview,start,end,location`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "outlook.body-content-type": "Text"
      }
    })
      .then(response => response.json())
      .then(data => {
        tst.hide();
        console.log(data.value);
        const parsed = parseEvents(data.value);
        updateCalendarEventTodos('o365', parsed, 'o365-default');
      })
      .catch(error => {
        tst.hide();
        toastError(`Could not load O365 Calendar events:<br>${error}`);
        console.log(error);
      });
  }

  function createDateAsUTC(date) {
    date = new Date(date);
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }
  
  function parseDateTime(datetime) {
    let date = datetime.dateTime;
    if (date) {
      date = createDateAsUTC(date);
    }
    return date;
  }

  function parseEvents(events) {
    const results = [];
    if (events && events.length > 0) {
      for (let i = 0; i < events.length; i++) {
        let event = events[i];
        let start = parseDateTime(event.start);
        let time = null;
        if (start) {
          time = start;
        } else {
          start = event.start.date;
        }
        let end = parseDateTime(event.end);
        if (!end) {
          end = event.end.date;
        }
        const result = {
          title: event.subject,
          start: start,
          time: time,
          id: event.id
        };
        if (event.bodyPreview) {
          result.description = event.bodyPreview;
        }
        if (end) {
          result.duration = new Date(end) - new Date(start);
        }
        results.push(result);
      }
    }
    return results;
  }
  
  if (oldToken) {
    localStorage.removeItem('mstoken');
    ui_updateSigninStatus(true, oldToken);
  } else {
    getAccessToken();
  }
}