import { translate, translateUi } from './i18n';
import { hideModal, showModal, showModalSelector } from './ui';

export function modalFull(name, title, content, submitTitle, submitAction, cancel, cancelAction, referringTodo) {
  return modal(name, title, content, submitTitle, submitAction, cancel, cancelAction, referringTodo, 'modal-fullscreen');
}

export function modalLg(name, title, content, submitTitle, submitAction, cancel, cancelAction, referringTodo) {
  return modal(name, title, content, submitTitle, submitAction, cancel, cancelAction, referringTodo, 'modal-lg');
}

/**
 * Prepares a modal with submit button and optional cancel button.
 * A self-destroying event listener invoking submitAction is registered.
 * submitAction receives the modal DOM element as parameter.
 */
export function modal(name, title, content, submitTitle, submitAction, cancel, cancelAction, referringTodo, extraClass = '') {
  let cancelHtml = '';
  if (cancel) {
    cancelHtml = `<button type="button" class="btn btn-secondary modal-cancel" id="${name}-modal-cancel" data-bs-dismiss="modal">${cancel}</button>`;
  }
  let todoHtml = '';
  if (referringTodo) {
    todoHtml = `data-todo="${referringTodo.id}"`;
  }
  const $modal = new DOMParser().parseFromString(`<div class="modal fade" id="${name}-modal" ${todoHtml} tabindex="-1" aria-labelledby="${name}-modal-label" aria-hidden="true">
  <div class="modal-dialog ${extraClass} ">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="${name}-modal-label">${title}</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ${content}
      </div>
      <div class="modal-footer">
        ${cancelHtml}
        <button type="button" class="btn btn-primary modal-submit" id="${name}-modal-submit">${submitTitle}</button>
      </div>
    </div>
  </div>
</div>
`, 'text/html').body.firstChild;
  document.body.appendChild($modal);
  translateUi();
  const submitButton = document.getElementById(name + '-modal-submit');
  selfDestroyingModalAction(submitButton, function () {
    const keepOpen = submitAction(document.getElementById(name + '-modal'));
    if (!keepOpen) {
      destroyModal($modal);
    }
  });
  if (cancelAction) {
    const cancelButton = document.getElementById(name + '-modal-cancel');
    selfDestroyingModalAction(cancelButton, evt => {
      const keepOpen = cancelAction(evt);
      if (!keepOpen) {
        destroyModal($modal);
      }
    });
  }
  showModal($modal);
  return $modal;
}

function selfDestroyingModalAction(button, action) {
  const fullAction = function () {
    button.removeEventListener('click', fullAction);
    action();
  };
  button.addEventListener('click', fullAction);
}
function destroyModal($modal) {
  if (!$modal) {
    return;
  }
  const modalEl = hideModal($modal);
  if (modalEl) {
    $modal.addEventListener('hidden.bs.modal', function () {
      try {
        modalEl.dispose();
        $modal.remove();
      } catch (error) {
        console.log('Error in hideModal.', error);
      }
    });
  }
}

let loadingModalShown = false;
export function loadingModalShow(text) {
  if (loadingModalShown) {
    console.log('loadingModalShow(): Loading modal already shown, doing nothing.');
  }  
  const modal = document.createElement('div');
  modal.id = 'loadingModal';
  modal.classList.add('modal', 'fade');
  modal.dataset.backdrop = 'static';
  modal.dataset.keyboard = 'false';
  modal.tabindex = -1;
  modal.setAttribute('aria-labelledby', 'loading-modal-label');
  modal.setAttribute('aria-hidden', 'true');
  const content = `<div class="modal-dialog modal-fullscreen">
    <div class="modal-content my-5">
      <div class="modal-body text-center my-5">
        <div class="i18n-sync-data" id="loading-modal-label">${translate(text)}</div>
        <div class="spinner-grow text-secondary mt-3" style="width: 4rem; height: 4rem;" role="status">
          <span class="sr-only i18n-loading">Loading...</span>
        </div>
      </div>
    </div>
  </div>`;
  modal.innerHTML = content;
  document.body.appendChild(modal);
  showModalSelector('#loadingModal');
  loadingModalShown = true;
}

export function loadingModalHide() {
  loadingModalShown = false;
  destroyModal(document.getElementById('loadingModal'));
}

// called on focus as the modal maybe still visible
export function checkLoadingModalHide() {
  if (loadingModalHide && !document.getElementById('loadingModal')) {
    console.log("Loading modal accidentally not closed, trying again.");
    loadingModalHide();
  }
}