import { Bucket } from "./bucket";
import { endOfWeek, fmtDate, plusDate, startOfDay, startOfMonth, startOfYear } from "./dates";
import { chooseDropboxFileDialog } from "./dropbox-sync";
import { closeHamburger } from "./events";
import { configureSettingsAndFeatures, disableFeatures, enableFeatures, isEnabled } from "./features";
import { chooseCalendars } from "./google-calendar";
import { NO_SHORTCUT, appendNavHeading, appendNavItem, appendNavItemConnect, appendNavItemOpt, appendNavSep, dueTimeStatus, titleAddAttention } from "./html";
import { modal, modalFull, modalLg } from "./modal";
import { current, start } from "./nav";
import { saveSettings, settings } from "./settings";

import StackTrace from 'stacktrace-js';
import { mainTour, markAllShown, miniSyncTour, pageInit, resetShown } from "./tour";
import { todoMap } from "./items";
import { editRepeatTimers } from "./repeattimers";
import { editAutoTags } from "./tags";
import { checkNewDayRefresh } from "./index";

export function globalErrorHandler() {
  window.onerror = function(msg, file, line, col, error) {
    if (error) {
      StackTrace.fromError(error).then(stackframes => {
        let stringifiedStack = stackframes.map(function(sf) {
          return sf.toString();
        }).join('\n');
        alert('Unexpected error: ' + msg + '\n\n' + stringifiedStack);
      }).catch(err => alert(err));
    } else {
      alert('Unexpected error: ' + msg);
    }
  };
}

export function enableDisableParameters() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.has('enable-feature')) {
    enableFeatures(urlParams.getAll('enable-feature'));
  }
  if (urlParams.has('disable-feature')) {
    disableFeatures(urlParams.getAll('disable-feature'));
  }
}

export function calcBuckets() {
  const today = startOfDay(new Date());
  let thisWeek = false;
  let monthBase = current;
  if (today >= start && today <= endOfWeek(start)) {
    thisWeek = true;
    monthBase = today;
  }
  return {
    'today': new Bucket(start, "today", "Today", today, "day"),
    'tomorrow': new Bucket(start, "tomorrow", "Tomorrow", plusDate(today, 1), "day"),
    'monday': new Bucket(start, "monday", "Monday", current, "day"),
    'tuesday': new Bucket(start, "tuesday", "Tuesday", plusDate(current, 1), "day"),
    'wednesday': new Bucket(start, "wednesday", "Wednesday", plusDate(current, 2), "day"),
    'thursday': new Bucket(start, "thursday", "Thursday", plusDate(current, 3), "day"),
    'friday': new Bucket(start, "friday", "Friday", plusDate(current, 4), "day"),
    'saturday': new Bucket(start, "saturday", "Saturday", plusDate(current, 5), "day"),
    'sunday': new Bucket(start, "sunday", "Sunday", plusDate(current, 6), "day"),
    'this-weekend': new Bucket(start, "this-weekend", "Weekend", plusDate(current, 5), "day", 2),
    'this-week': new Bucket(start, "this-week", "This Week", current, "week"),
    'this-week-strict': new Bucket(start, "this-week-strict", "This Week", current, "week"),
    'next-week': new Bucket(start, "next-week", "Next Week", plusDate(current, 7), "week"),
    'this-month': new Bucket(start, "this-month", "This Month", fmtDate(startOfMonth(monthBase)), "month", monthBase),
    'this-month-strict': new Bucket(start, "this-month-strict", "This Month", fmtDate(startOfMonth(monthBase)), "month", monthBase),
    'this-year': new Bucket(start, "this-year", "This Year", fmtDate(startOfYear(monthBase)), "year", monthBase),
    'this-year-strict': new Bucket(start, "this-year-strict", "This Year", fmtDate(startOfYear(monthBase)), "year", monthBase),
    'eventually': new Bucket(start, "eventually", "Eventually", null, "eventually"),
    'overdue': new Bucket(start, "overdue", "Overdue", current, "before"),
  };
}

export function menu() {
  const menu = document.getElementById('hamburger-menu');
  appendNavItem(menu, 'to-search', 'Search', 'Strg-Shift-F');
  appendNavSep(menu);
  appendNavItem(menu, 'add-todo-button', 'Add', '+');
  appendNavItem(menu, 'sync-save-button', 'Save', 'Strg-S');
  appendNavItemOpt(menu, 'delete-all', 'Delete All Todos');
  appendNavSep(menu);
  appendNavItem(menu, 'preferences', 'Preferences', 
    NO_SHORTCUT, configureSettingsAndFeatures, {en: 'and additional features.', de: 'und weitere Funktionen'});
  if (isEnabled('repeated-timers')) {
    appendNavItem(menu, 'repeattimers', 'Repeated Timers', 
      NO_SHORTCUT, editRepeatTimers);
  }
  if (isEnabled('auto-tags')) {
    appendNavItem(menu, 'autotags', 'Auto Tags', 
      NO_SHORTCUT, editAutoTags);
  }
  if (isEnabled('dropbox-sync')) {
    appendNavSep(menu);
    appendNavHeading(menu, 'Dropbox');
    appendNavItemConnect(menu, 'dropbox', true);
    appendNavItem(menu, 'dropbox-file', 'Choose File <small class="filename"></small>', 
      NO_SHORTCUT, chooseDropboxFileDialog);
  }
  if (isEnabled('google-calendar')) {
    appendNavSep(menu);
    appendNavHeading(menu, 'Google Calendar');
    appendNavItemConnect(menu, 'google');
    appendNavItem(menu, 'google-calendar-list', 'Choose Calendars(s) <small class="calendars"></small>',
      NO_SHORTCUT, chooseCalendars);
  }
  if (isEnabled('o365-calendar')) {
    appendNavSep(menu);
    appendNavHeading(menu, 'O365 Calendar');
    appendNavItemConnect(menu, 'o365', true);
  }
  appendNavSep(menu);
  appendNavItemOpt(menu, 'calendar-delete-button', 'Delete Calendar Entries');  
  appendNavItemOpt(menu, 'purge-todos-button', 'Purge Todos');
  appendNavSep(menu);
  appendNavItem(menu, 'about-tio', 'About TIO', NO_SHORTCUT, () => {
    closeHamburger();
    aboutTioModal();
  });  
}

export function aboutTioModal() {
  modalFull('intro', 'TIO Planner', 
  pageInit(), 
  'Yes, give me the tour!', 
    modalTour => {
      settings.introshown = new Date();
      saveSettings();
      resetShown();
      mainTour();
    },
    'No tour, I know how it works.',
    modalNoTour => {
      settings.introshown = new Date();
      saveSettings();
      markAllShown();
    });
}

export function initSmallStatus() {
  const elem = document.getElementById('small-status');

  let scrollFunc = function() {
    let y = window.scrollY;
    if (y >= 50) {
      elem.classList.remove('d-none');
      miniSyncTour();
    } else {
      elem.classList.add('d-none');
    }
  };

  window.addEventListener("scroll", scrollFunc);
}

/**
 * On every minute start, check if due-time status needs to be updated and if
 * it is a new day.
 */
export function initOnceAMinuteUpdater() {
  const nowSeconds = new Date().getSeconds();
  setTimeout(()=>{
    onceAMinuteUpdater();
    setInterval(onceAMinuteUpdater, 60 * 1000);
  }, (60 - nowSeconds) * 1000);
}

function onceAMinuteUpdater() {
  checkNewDayRefresh();
  const todayDueTimes = document.querySelectorAll('.today .due-time');
  for (let i = 0; i < todayDueTimes.length; i++) {
    const dueTime = todayDueTimes[i];
    const input = dueTime.closest('.todo-container').querySelector('input');
    const id = input.getAttribute('id');
    const todo = todoMap.get(id);
    if (!todo.done) {
      const status = dueTimeStatus(todo);
      const classes = dueTime.classList;
      if (status !== '' && !classes.contains(status)) {
        classes.remove('text-danger', 'text-warning', 'text-primary');
        if (!classes.contains('blink')) {        
          classes.add(status, 'blink');
          titleAddAttention(true);
        }
      }
    }
  }
}