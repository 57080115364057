import { saveSettings, settings } from './settings';
import { translate, uiLanguage } from './i18n';
import { closeHamburger } from './events';
import { modal, modalLg } from './modal';

const allSettings = [
  {
    name: 'behavior',
    settings: [
      { name: 'essential-view',
        description: {
          en: 'Reduce cards in current week default display.',
          de: 'In der aktuellen Woche nur die wichtigsten Karten anzeigen.'
        }
      },
      { name: 'confetti',
        description: {
          en: 'Show confetti when all todos of one day are completed.',
          de: 'Konfetti anzeigen, wenn alle Aufgaben eines Tages erledigt sind.'
        },
        defaultValue: true
      },
      { name: 'purge',
        description: {
          en: 'Purge old done or deleted todos on a regular base.',
          de: 'Regelmäßig alte erledigte oder gelöschte Aufgaben bereinigen.'
        },
        defaultValue: 'ask',
        values: ['ask', 'disallow', 'allow']
      },
      { name: 'default-duration',
        description: {
          en: 'Preselected duration for a todo.',
          de: 'Vorausgewählte Dauer einer Aufgabe.'
        },
        defaultValue: '15m',
        values: ['', '5m', '10m', '15m', '30m', '1h', '2h']
      },
      { name: 'weekly-overdue',
        description: {
          en: 'In future weeks also show todos after today as overdue.',
          de: 'In zukünftigen Wochen auch kommende Aufgaben als überfällig anzeigen.'
        }
      }
    ],
    bgcolor: 'white'
  },{
    name: '3rd-party',
    description: {
      en: 'Enable connections to external services.<br/><b>Will send requests to 3rd party servers and create cookies.</b><br/>If you do not want that, do not enable these settings.',
      de: 'Verbindungen zu externen Diensten aktivieren.<br/><b>Führt dazu, dass Anfragen an Drittanbieter-Server gesendet und dass Cookies erzeugt werden.</b><br/>Wenn du das nicht möchtest, aktiviere diese Einstellungen nicht.'
    },
    settings: [
      { name: 'dropbox-sync',
        description: {
          en: 'Sync todos with Dropbox. Will send requests to Dropbox servers and create cookies.',
          de: 'Aufgaben mit Dropbox synchronisieren.'
        }
      },
      {
        name: 'google-calendar',
        description: {
          en: 'Add Google Calendar events as todo items. Will send requests to Google servers and create cookies.',
          de: 'Google Kalender Termine als Todo-Einträge übernehmen.'
        }
      },
    ]
  },
  {
    name: 'experimental',
    description: {
      en: 'Enable experimental features. These will not yet fully work as expected.',
      de: 'Experimentelle Funktionen aktivieren. Diese funktionieren noch nicht vollständig wie erwartet.'
    },
    settings: [
      {
        name: 'o365-calendar',
        description: {
          en: 'Add O365 Calendar events as todo items. Will send requests to Microsoft servers and create cookies.',
          de: 'O365 Kalender Termine als Todo-Einträge übernehmen. <b>Führt dazu, dass Anfragen an Microsoft-Server gesendet und dass Cookie erzeugt werden.</b>'
        }
      },
      {
        name: 'repeated-timers',
        description: {
          en: 'Show configured, repeated alarms after a timer has run out.',
          de: 'Konfigurierte, wiederholte Alarme nach bestimmten Zeiträumen anzeigen.'
        }
      },
      {
        name: 'auto-tags',
        description: {
          en: 'Automatically assign tags based on todo properties.',
          de: 'Automatisch Tags anhand von Todo-Eigenschaften zuordnen.'
        }
      }
    ],
    bgcolor: '#f0e840'
  },
  {
    name: 'debug',
    description: {
      en: 'Enable developer features. <b>Use at your own risk!</b>',
      de: 'Entwickler-Funktionen aktivieren. <b>Auf eigene Gefahr!</b>'
    },
    settings: [
      {
        name: 'purge-todos-button',
        description: {
          en: 'Add menu item to delete all done or deleted todos.',
          de: 'Menüeintrag zum Löschen aller erledigten oder gelöschten Aufgaben.'
        }
      },
      { name: 'calendar-delete-button',
        description: {
          en: 'Add menu item to delete all imported calendar entries.',
          de: 'Menüeintrag zum Löschen aller importierten Kalender-Aufgaben.'
        }},
      { name: 'delete-all',
        description: {
          en: 'Add menu item to delete <em><b>ALL</b></em> todos. <b>Use carefully!</b>',
          de: 'Menüeintrag zum Löschen <em><b>ALLER</b></em> Aufgaben. <b>Nur mit Vorsicht benutzen!</b>'
       } },
      { name: 'debug-properties',
        description: {
          en: 'Show all todo properties in details dialog.',
          de: 'Alle Eigenschaften einer Aufgabe im Details-Dialog anzeigen.'
      }},
      { name: 'debug-prio',
      description: {
        en: 'Store the priority trend of each todo item and show in details dialog.',
        de: 'Die Entwicklung der Priorität jeder Aufgabe speichern und im Details-Dialog anzeigen.' 
      }},
      { name: 'mobile-console',
      description: {
        en: 'Load remote JavaScript to enable a mobile console.',
        de: 'Externes JavaScript laden, um eine mobile Konsole zu verwenden.' 
      }},
    ],
    bgcolor: '#f86373'
  },
];

const defaultEnabled = [
  'confetti'
];

function ensureEnabledfeatures() {
  if (!settings.hasOwnProperty('enabledfeatures')) {
    settings.enabledfeatures = [...defaultEnabled];
  }
  return settings.enabledfeatures;
}

export function isEnabled(id) {
  const enabled = ensureEnabledfeatures();
  if (enabled && enabled.includes(id)) {
    return true;
  }
  return false;
}

export function enableFeatures(features) {
  const enabled = ensureEnabledfeatures();
  features.forEach(ft => {
    if (!enabled.includes(ft)) {
      enabled.push(ft);
    }
  });
  saveSettings();
}

export function disableFeatures(features) {
  const enabled = ensureEnabledfeatures();
  features.forEach(ft => {
    for (let i = 0; i < enabled.length; i++) {                   
      if (enabled[i] === ft) { 
        enabled.splice(i, 1); 
        i--; 
      }
    }
  });
  saveSettings();
}

export function configureSettingsAndFeatures() {
  closeHamburger();
  let list = '';
  allSettings.forEach(settingsMenu => {
    const area = settingsMenu.name;
    const color = settingsMenu.bgcolor;
    const description = settingsMenu.description;
    list = list + `<hr><h5 class="mt-3 pt-3">${translate('settingsarea-' + area)}</h5>`;
    if (description) {
      if (description[uiLanguage]) {
        list = list + '<p>' + description[uiLanguage] + '</p>';
      } else if (description.en) {
        list = list + '<p>' + description.en + '</p>';
      } 
    }
    let border = 'border';
    settingsMenu.settings.forEach(setting => {
      const name = setting.name;
      let description = '';
      if (setting.description) {
        if (setting.description[uiLanguage]) {
          description = `<br><small>${setting.description[uiLanguage]}</small>`;
        } else if (setting.description.en) {
          description = `<br><small>${setting.description.en}</small>`;
        }
      }
      if (setting.values) {
        const options = setting.values.map(v => {
          const selected = settings[name] === v ? ' selected="selected"' : '';
          return `<option value="${v}"${selected}>${translate('setting-' + name + '-' + v, v)}</option>`;
        });
        list = list + `<div class="row py-3 ${border}" style="background-color: ${color};">
          <div class="col-sm-2 text-end">
            <select class="form-select form-select-sm" id="choose-setting-${name}" data-setting="${name}">
              ${options}
            </select>
          </div>
          <div class="col-sm-10">
            <label class="form-check-label" for="choose-setting-${name}">${translate('setting-' + name)}</label>
            ${description}
          </div>
        </div>`;
      } else {
        const enabled = isEnabled(name) ? ' checked="checked"' : '';
        list = list + `<div class="row py-3 ${border}" style="background-color: ${color};">
          <div class="col-sm-2 text-end">            <div class="form-check">
            <input type="checkbox" class="form-check-input" id="choose-setting-${name}" data-setting="${name}" ${enabled}>
          </div></div>
          <div class="col-sm-10">
            <label class="form-check-label" for="choose-setting-${name}">${translate('setting-' + name)}</label>
            ${description}
          </div>
        </div>`;
      }
      border = 'border border-top-0';
    });  
  });
  const dm = modalLg('preferences', 'Preferences', 
  `<p><span>Enable or disable settings:</span></p>
  <div class="container form-group">
    ${list}
  </div>`, 'Accept', evt => updateFeatures(dm));
}

function updateFeatures($modal) {
  const features = $modal.querySelectorAll('input[type="checkbox"]');
  const toEnable = [];
  const toDisable = [];
  features.forEach(s => {
    const id = s.dataset.setting;
    if (s.checked) {
      toEnable.push(id); 
    } else {
      toDisable.push(id);
    }
  });
  enableFeatures(toEnable);
  disableFeatures(toDisable);

  const otherSettings = $modal.querySelectorAll('select');
  otherSettings.forEach(s => {
    const id = s.dataset.setting;
    const value = s.options[s.selectedIndex].value;
    settings[id] = value;
    saveSettings();
  });

  window.location.reload();
}