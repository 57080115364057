import isReachable from 'is-reachable';
import { clean, offline } from './html';

let lastCheck = 0;
let lastOnline = false;

export async function checkOnlineState() {
    const now = Date.now();
    if (now - lastCheck > 10000) {
        lastCheck = now;
        const hostname = window.location.hostname;
        lastOnline = await isReachable(hostname, {timeout: 1000})
        .then(function (online) {
            if (lastOnline && !online) {
                console.log("Now offline.");
                offline();
            } else if (!lastOnline && online) {
                console.log("Now online.");
                clean();
            }
            return online;
        });
    }
    return lastOnline;
}